// @flow

import { FAIL, SUCCESS, mixpanelTrackBulkCreateOrders } from '@app/utils/mixpanelUtils'
import {
  LODGE_IN_STATUS_REQUEST,
  MAX_FAILURE_RETRY,
  MAX_POLL_COUNT,
  POLL_DELAY_MS,
  RETRY_DELAY_MS
} from '@app/containers/BulkUpload/constants'
import { all, call, delay, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { bulkUploadCreators, bulkUploadTypes } from '@app/reducers/BulkUpload'
import {
  constructOrder,
  constructPostOrderRequestPayload,
  enrichOrderWithPostOrderResponse
} from '@app/utils/OrderUtils'
import {
  createPostOrderApi,
  createSendOrderApi,
  dpApi,
  getLodgeInByStatusId,
  getOrderDetailsApi,
  getShipperByExternalIdApi,
  getShipperByIdApi
} from '@app/services/api'
import { selectCountry, selectDpId, selectDpInfo } from '@app/containers/Base/selectors'
import { trackCustomEvent, trackPostOrderStatusEvent } from '@app/utils/trackCustomEvent'

import type { BulkPostOrderCsvRow } from '@app/types/BulkPostOrderCsvRow'
import type { DpInfo } from '@app/types/DpInfo'
import { ROUTES } from '@app/utils/constants'
import type { Response } from '@app/types/Response'
import type { Saga } from 'redux-saga'
import type { SendOrderFailureResponse } from '@app/types/SendOrderResponse'
import type { SendOrderRequestPayload } from '@app/types/SendOrderRequestPayload'
import type { SenderDetailsResponse as SenderDetails } from '@app/types/SenderDetailsResponse'
import _ from 'lodash'
import { baseCreators } from '@app/containers/Base/redux'
import { parseResponse } from '@app/utils/apiUtils'

const { showLoading, showError, showInfo, showProcessingLodgeInModal } = baseCreators

export function * retryGetLodgeIn (country: string, statusId: string): Saga {
  for (let i = 0; i < MAX_FAILURE_RETRY; i++) {
    const response: Response<any> = yield call(getLodgeInByStatusId, country, statusId)

    if (response.ok) {
      return response
    }

    if (i < MAX_FAILURE_RETRY - 1) {
      yield delay((RETRY_DELAY_MS / 1000) ** i * 1000)
    }
  }

  // attempts failed after 5 attempts
  throw new Error('getLodgeInByStatusId API failed')
}

export function * pollLodgeIn (country: string, statusId: string): Saga {
  let pollCount = 0
  while (pollCount <= MAX_POLL_COUNT) {
    const response: Response<any> = yield call(retryGetLodgeIn, country, statusId)
    if (_.get(response, 'data.data.status', '') === LODGE_IN_STATUS_REQUEST.PROCESSED || pollCount === MAX_POLL_COUNT) {
      return response
    }

    pollCount += 1
    yield delay(POLL_DELAY_MS)
  }
}

export function * bulkSend (action: Object): Saga {
  const trackingIds: string[] = action.trackingIds
  const country = yield select(selectCountry())
  const dpId = yield select(selectDpId())
  const shouldNotifyShipper = true

  const payload: SendOrderRequestPayload = {
    reservations: trackingIds.map(trackingId => ({ trackingId }))
  }

  const sendResponse: Response<any> = yield call(createSendOrderApi, country, shouldNotifyShipper, payload)

  if (sendResponse.ok && _.get(sendResponse, 'data.data.statusId')) {
    const { statusId } = sendResponse.data.data

    try {
      const lodgeInResponse: Response<any> = yield call(pollLodgeIn, country, statusId)

      if (_.get(lodgeInResponse, 'data.data.status', '') === LODGE_IN_STATUS_REQUEST.PROCESSING) {
        const info = {
          title: 'lodge_in_request_accepted',
          message: 'lodge_in_processing_message',
          redirectPage: ROUTES.history.path
        }
        //Showing the new processing modal & Resetting the bulk upload creation status to hide the loader incase of new bulk upload page.
        if(window.location.pathname === ROUTES.sendParcelV2.path) {
          yield put(showProcessingLodgeInModal(true))
          yield put(bulkUploadCreators.finishBulkSend(0, []))
        } else {
          yield put(showInfo(info))
        }
        return
      }
      const successfulOrders: number = _.get(lodgeInResponse, 'data.data.successfulOrders', 0)
      const failedOrders: { trackingId: string, reason: string }[] = _.map(
        _.get(lodgeInResponse, 'data.data.failedOrders', []),
        result => ({
          trackingId: result.barcode,
          reason: result.errorMessage
        })
      )
      trackCustomEvent('bulksend', { successOrders: successfulOrders, failedOrders: failedOrders.length })
      yield put(bulkUploadCreators.finishBulkSend(successfulOrders, failedOrders))
    } catch (err) {
      yield put(showError(err.message))
      yield put(bulkUploadCreators.finishBulkSend(0, []))
      trackCustomEvent('bulksend', { successOrders: 0, failedOrders: trackingIds.length })
    }
  } else {
    const failureResponse: SendOrderFailureResponse = sendResponse
    const error = (failureResponse.data.error.title === 'BR_EXCEEDED_MAX_BULK_RESERVATION') ? {
      title: failureResponse.data.error.title,
      message: failureResponse.data.error.message
    } : failureResponse.data.error.message
    yield put(showError(error))
    yield put(bulkUploadCreators.finishBulkSend(0, []))
    trackCustomEvent('bulksend', { successOrders: 0, failedOrders: trackingIds.length })
  }
}

export function * getShippersByIds (ids: number[] = []): Saga {
  return yield all(ids.map(id => call(getShipperById, id)))
}

export function * getShippersByExternalIds (ids: number[] = []): Saga {
  return yield all(ids.map(id => call(getShipperByExternalId, id)))
}

export function * getShipperById (id: number): Saga {
  const country = yield select(selectCountry())
  const response = yield call(getShipperByIdApi, country, id)
  const { data } = parseResponse(response)
  if (!response.ok) {
    return {}
  }
  return data
}

export function * getShipperByExternalId (id: number): Saga {
  const country = yield select(selectCountry())
  const response = yield call(getShipperByExternalIdApi, country, id)
  const { data } = parseResponse(response)
  if (!response.ok) {
    return {}
  }
  return data
}

/**
 * Create POST orders in bulk
 *
 * @param {*} action - Redux action containing the order details for all shippers
 */
export function * createPostOrders (action: Object): Saga {
  const country: string = yield select(selectCountry())
  const dpInfo: DpInfo = yield select(selectDpInfo())

  const bulkPostOrders: BulkPostOrderCsvRow[] = _.get(action, 'orders', [])
  const numberOfOrdersUploaded = bulkPostOrders.length
  const shipperIds: string[] = bulkPostOrders
    .filter((order: BulkPostOrderCsvRow) => order && order.shipper_id)
    .map((order: BulkPostOrderCsvRow) => order.shipper_id)
  
  const externalShipperIds: string[] = bulkPostOrders
    .filter((order: BulkPostOrderCsvRow) => order && order.external_shipper_id)
    .map((order: BulkPostOrderCsvRow) => order.external_shipper_id)

  const isHideLegacyShipperIdEnabled = externalShipperIds && externalShipperIds.length

  const shippers = (isHideLegacyShipperIdEnabled)? yield call(getShippersByExternalIds, _.uniq(externalShipperIds)) :yield call(getShippersByIds, _.uniq(shipperIds))
  let receiptDetails;

  const ordersGroupByShipperId = {}

  isHideLegacyShipperIdEnabled ? externalShipperIds.forEach(externalId => {
    ordersGroupByShipperId[externalId] = []
  }) : shipperIds.forEach(shipperId => {
    ordersGroupByShipperId[shipperId] = []
  })
  const uniqueShipperCount = _.keys(ordersGroupByShipperId).length
  let hasInvalidShipperId = false

  bulkPostOrders.forEach(bulkPostOrder => {
    const order = constructOrder(bulkPostOrder)
    const shipper = (isHideLegacyShipperIdEnabled) ? shippers.find(shipper => shipper.externalId === bulkPostOrder.external_shipper_id)
      : shippers.find(shipper => parseInt(shipper.id) === parseInt(bulkPostOrder.shipper_id))
    
    if (!shipper) {
      hasInvalidShipperId = true
    } else {
      const senderDetails: SenderDetails = {
        globalId: _.get(shipper, 'globalId'),
        id: _.get(shipper, 'id'),
        isDefault: _.get(shipper, 'isDefault'),
        isPrepaid: _.get(shipper, 'isPrepaid'),
        liaisonAddress: _.get(shipper, 'liaisonAddress'),
        liaisonContact: _.get(shipper, 'liaisonContact'),
        liaisonEmail: _.get(shipper, 'liaisonEmail'),
        liaisonName: _.get(shipper, 'liaisonName'),
        liaisonPostcode: _.get(shipper, 'liaisonPostcode'),
        name: _.get(shipper, 'liaisonName')
      };

      (isHideLegacyShipperIdEnabled) ? ordersGroupByShipperId[shipper?.externalId]?.push([order, senderDetails])
        : ordersGroupByShipperId[shipper?.id]?.push([order, senderDetails])
    }
  })

  if (hasInvalidShipperId) {
    const errorDetails = (isHideLegacyShipperIdEnabled) ? {
      title: 'bulk_upload_invalid_external_shipper_title',
      message: 'bulk_upload_invalid_external_shipper_error_message',
      shouldTranslate: true
    } : {
      title: 'bulk_upload_invalid_shipper_title',
      message: 'bulk_upload_invalid_shipper_error_message',
      shouldTranslate: true
    }
    errorDetails && (yield put(showError(errorDetails)))
    yield put(bulkUploadCreators.failCreatePostOrders())
    return
  }
  
  const responses = yield call(createPostOrdersForAllShippers, country, dpInfo, ordersGroupByShipperId)

  // Flatten and get the data field from each response
  const responseData = responses.reduce((allResponses, currentResponse) => {
    return allResponses.concat(currentResponse.data)
  }, [])

  const listOfSuccessfulOrders = responseData.filter(response => response.data!== null)

  // Since all orders created under the same shipper have the same receipt details, we can call getOrderDetailsApi endpoint once to fetch A4 and A6 receipts information 
  if (uniqueShipperCount === 1) {
    if (_.size(listOfSuccessfulOrders) > 0) {
      const trackingId = listOfSuccessfulOrders[0].data.reservation.barcode 
      receiptDetails = yield call(getOrderDetailsApi, country, trackingId)
    }
  }

  // Map each order to their response
  // Note: Skip the check for response.ok since BE sends it as true whether order creation was successful or failed
  let isAllOrdersCreatedSuccessfully = true

  const processedOrders = []
  for (const key of Object.keys(ordersGroupByShipperId)) {
    const ordersForOneShipper = ordersGroupByShipperId[key]
    for (const orderTuple of ordersForOneShipper ) {
      const order = orderTuple[0]
      order.senderDetails = orderTuple[1]

      const orderResponse =
        _.find(responseData, ['data.reservation.stamp_id', order.stampId.id]) ||
        _.find(responseData, ['error.stamp_id', order.stampId.id]) ||
        _.find(responseData, 'error.message')

      if (_.find(responseData, ['error.stamp_id', order.stampId.id]) ||
          _.find(responseData, 'error.message')
      ) {
        isAllOrdersCreatedSuccessfully = false
      }

      //  Since orders created under different shipper have different A4/A6 receipts, we need to call getOrderDetailsApi to fetch A4/A6 receipt information for of the each order
      if (uniqueShipperCount > 1) { 
        const trackingId = orderResponse?.data?.reservation?.barcode 
        if (orderResponse?.data?.reservation?.barcode !== undefined) {
          receiptDetails = yield call(getOrderDetailsApi, country, trackingId)
        }
      }

      order.receipts = receiptDetails?.data?.data?.receipts
      processedOrders.push(enrichOrderWithPostOrderResponse(order, orderResponse, order.senderDetails))
    }
  }

  yield put(bulkUploadCreators.updateBulkPostOrders(processedOrders))
  yield put(bulkUploadCreators.successCreatePostOrders())
  const uploadOutcome = isAllOrdersCreatedSuccessfully ? SUCCESS : FAIL
  mixpanelTrackBulkCreateOrders(uploadOutcome, numberOfOrdersUploaded, uniqueShipperCount)

  trackPostOrderStatusEvent('bulkpost', processedOrders, true)
}

/**
 * Create POST orders for all shippers by sending one OC request for each shipper
 *
 * @param {string} country - country of current NPv3 user
 * @param {Object} dpInfo - DP information and DP settings
 * @param {Object} ordersGroupByShipperId - Order details grouped by shipper ID
 * @returns Array of responses from OC request for each shipper
 */
export async function createPostOrdersForAllShippers (country, dpInfo, ordersGroupByShipperId) {
  const isDraft = false
  const isBulkOrderCreation = true
  const MAX_ORDERS_PER_FETCH_PRICES_BY_SHIPPER_ID_REQUEST = 30
  const ordersRequests = []
  Object.keys(ordersGroupByShipperId).forEach((shipperId) => {
    for (let i = 0; i < ordersGroupByShipperId[shipperId].length; i += MAX_ORDERS_PER_FETCH_PRICES_BY_SHIPPER_ID_REQUEST) {
      const batchOrders = ordersGroupByShipperId[shipperId].slice(i, i + MAX_ORDERS_PER_FETCH_PRICES_BY_SHIPPER_ID_REQUEST)
      const payload = constructPostOrderRequestPayload(batchOrders, dpInfo, country, isDraft, isBulkOrderCreation)
      ordersRequests.push(createPostOrderApi(country, payload))
    }
  })
  return Promise.all(ordersRequests)
}

export default function * bulkUploadData (): Saga {
  yield all([
    takeLatest(bulkUploadTypes.REQUEST_BULK_SEND, bulkSend),
    takeEvery(bulkUploadTypes.REQUEST_CREATE_POST_ORDERS, createPostOrders)
  ])
}
