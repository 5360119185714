import { DELIVERY_TYPES, OC_STATUS, isEqual } from '@app/utils/constants'
import { createActions, createReducer } from 'reduxsauce'

import { BULK_OC_LIMIT } from './constants'
import _ from 'lodash'
import { constructEditOrderDetails } from '@app/utils/OrderUtils'
import { fromJS } from 'immutable'
import { reservations } from '@app/constants'
import { setIn } from '@app/utils/reducerUtils'

const { ORDER_TYPES } = reservations
export const GET_PRICE = 'getPrice'

export const { Types: firstMileTypes, Creators: firstMileCreators } = createActions(
  {
    requestGetOrder: ['id', 'path', 'index', 'onSuccess', 'onFailure'],
    requestGetOrderWithoutStampId: [],
    successGetSendOrder: ['order'],
    successGetPackParcel: ['trackingId', 'index'],
    successGetPostParcel: ['stampId', 'index'],
    failGetOrder: [],
    updateCurrentSession: ['session'],
    resetSession: [],
    requestGetShippers: ['value', 'searchAll', 'form', 'messages'],
    successGetShippers: ['shippers'],
    failGetShippers: [],
    selectShipper: ['shipper'],
    requestSetDefaultShipper: ['id'],
    successSetDefaultShipper: ['id'],
    failSetDefaultShipper: [],
    resetShipper: [],
    requestCreateShipper: ['shipper'],
    successCreateShipper: [],
    failCreateShipper: [],
    requestEditShipper: ['shipper', 'onSuccess'],
    successEditShipper: [],
    failEditShipper: [],
    removeParcel: ['index'],
    removeOrder: ['index'],
    requestUploadImage: ['file', 'index', 'afterUpload'],
    successUploadImage: ['image', 'index'],
    failUploadImage: [],
    resetImage: ['index'],
    updateParcel: ['parcel', 'index'],
    requestGetPrice: ['parcel', 'index', 'shipperId', 'globalShipperId', 'isQuickAddressFeatureEnabled'],
    successGetPrice: ['price', 'index'],
    failGetPrice: [],
    resetPrice: ['index'],
    resetOrder: [],
    startNewOrder: ['startTime'],
    requestLodgeIn: ['orders', 'lodgeInDetails', 'afterHandover'],
    requestCreatePostOrder: ['order', 'isQuickAddressFeatureEnabled', 'isDraft', 'afterHandover'],
    requestCreatePackOrder: ['order', 'isManualPack', 'afterHandover'],
    finishCreateOrder: ['orders'],
    failCreateOrder: [],
    requestBulkLodgeIn: ['trackingIds'],
    finishBulkLodgeIn: ['successfulOrders', 'failedOrders'],
    successGetReceiptId: ['id'],
    failGetReceiptId: [],
    requestGenerateReceipt: ['params', 'onSuccess'],
    successGenerateReceipt: [],
    failGenerateReceipt: [],
    requestGenerateAwb: ['tids', 'paperSize', 'onSuccess', 'onFailure'],
    successGetReturnOrder: ['order'],
    requestRetryCreatePostOrder: ['parcel', 'index', 'isDraft', 'afterHandover'],
    requestRetryCreatePackOrder: ['parcel', 'index', 'afterHandover'],
    requestEditOrder: ['order', 'afterHandover', 'isQuickAddressFeatureEnabled'],
    requestGetEditOrderDetails: ['trackingId', 'isQuickAddressFeatureEnabled'],
    successGetEditOrderDetails: ['order', 'country'],
    failGetEditOrderDetails: [],
    requestGetReceipt: ['idList'],
    requestGetSingleReceipt: ['id', 'isPrepaid'],
    successGetReceipt: [],
    failGetReceipt: [],
    requestTriggerNotifications: ['params'],
    successTriggerNotifications: [],
    failTriggerNotifications: [],
    updateBulkOcOrders: ['orders'],
    requestValidateStampId: ['stampId','onSuccess', 'callback', 'isStampIdOptional'],
    updateOrder: ['order', 'index'],
    updateSuccessfulShippers: [],
    fetchShipperSetupStatus: ['status'],
    resetShipperSetupStatus: ['status'],
    requestShipperSetupStatus: ['legacyShipperId', 'globalShipperId'],
    updateShipperPersonalInfo: ['shipper'],
    requestRegenerateOrderReceipts: ['params', 'onSuccess'],
  },
  { prefix: 'FirstMile/' }
)

export const DEFAULT_POST_PARCEL = {
  weight: 1,
  deliveryType: DELIVERY_TYPES.STANDARD,
  stampId: {
    isVerified: false
  }
}
export const DEFAULT_PACK_PARCEL = {
  // Hard-code pack total cost to 0
  cost: {
    total: 0.0
  }
}
export const DEFAULT_ORDER = {
  parcels: []
}

export const toggleLoading = (state, key, value) => {
  return state.setIn(['loadingStatus', key], value)
}
export const getBasePath = state => {
  const session = state.get('currentSession')
  switch (session) {
    case ORDER_TYPES.post:
    case ORDER_TYPES.bulkPost:
      return 'postOrder'
    case ORDER_TYPES.send:
      return 'sendOrders'
    case ORDER_TYPES.return:
      return 'returnOrders'
    default:
      return 'packOrder'
  }
}
export const getValueAndPath = (state, key) => {
  const basePath = getBasePath(state)
  const path = key ? [basePath, key] : [basePath]
  const value = state.getIn(path)
  return { value, path }
}
export const updateSessionValues = (state, path, updatedValue) => {
  const session = state.get('currentSession')
  switch (session) {
    case ORDER_TYPES.post:
    case ORDER_TYPES.bulkPost:
    case ORDER_TYPES.pack:
    case ORDER_TYPES.send:
    case ORDER_TYPES.return:
      return state.setIn(path, updatedValue)
    default:
      return state
  }
}

// Bulk OC
export const updateBulkOCOrderStatus = (state, index, status) => {
  const orders = state.getIn(['bulkOC', 'orders'])
  const updatedOrders = orders.set(index, orders.get(index).merge({ status }))
  return setIn(state, ['bulkOC', 'orders'], updatedOrders)
}
export const incrementBuklOCIndex = state => {
  return state.updateIn(['bulkOC', 'ocIndex'], v => v + 1)
}

export const INITIAL_STATE = fromJS({
  loadingStatus: {},
  generateReceiptFailed: false,
  currentSession: null,
  shippers: {
    data: [],
    source: null
  },
  sendOrders: [],
  postOrder: Object.assign({}, DEFAULT_ORDER),
  packOrder: Object.assign({}, DEFAULT_ORDER),
  returnOrders: [],
  returnOrder: {},
  bulkLodgeInStatus: {
    failedOrders: [],
    successfulOrders: 0
  },
  bulkOC: {
    ocIndex: BULK_OC_LIMIT - 1,
    orders: []
  },
  shipperSetupStatus: false
})

export const firstMileReducer = createReducer(INITIAL_STATE, {
  [firstMileTypes.REQUEST_GET_ORDER]: state => toggleLoading(state, 'getOrder', true),
  [firstMileTypes.REQUEST_GET_ORDER_WITHOUT_STAMP_ID]: state => toggleLoading(state, 'getOrder', true),
  [firstMileTypes.SUCCESS_GET_SEND_ORDER]: (state, action) => {
    const sendOrders = state.get('sendOrders')
    state = toggleLoading(state, 'getOrder', false)
    return state.set('sendOrders', [...sendOrders, action.order])
  },
  [firstMileTypes.SUCCESS_GET_PACK_PARCEL]: (state, action) => {
    const { trackingId, index } = action
    state = toggleLoading(state, 'getOrder', false)
    const { value: parcels, path } = getValueAndPath(state, 'parcels')
    if (index >= 0 && parcels.length) {
      _.set(parcels[index], 'trackingId', trackingId)
      return updateSessionValues(state, path, [...parcels])
    } else {
      const newParcel = Object.assign({}, DEFAULT_PACK_PARCEL, { trackingId })
      return updateSessionValues(state, path, [...parcels, { ...newParcel }])
    }
  },
  [firstMileTypes.SUCCESS_GET_POST_PARCEL]: (state, action) => {
    const { stampId, index } = action
    state = toggleLoading(state, 'getOrder', false)
    const { value: parcels, path } = getValueAndPath(state, 'parcels')
    if (index >= 0 && parcels.length) {
      _.set(parcels[index], 'stampId', stampId)
      return updateSessionValues(state, path, [...parcels])
    } else {
      const newParcel = Object.assign({}, DEFAULT_POST_PARCEL, { stampId: { id: stampId, isVerified: true } })
      return updateSessionValues(state, path, [...parcels, { ...newParcel }])
    }
  },
  [firstMileTypes.SUCCESS_GET_RETURN_ORDER]: (state, action) => {
    const { order } = action
    const returnOrders = state.get('returnOrders')
    state = toggleLoading(state, 'getOrder', false)
    const newOrder = Object.assign({}, order, { stampId: { id: order.stampId, isVerified: true } })
    return state.set('returnOrders', [...returnOrders, newOrder])
  },
  [firstMileTypes.FAIL_GET_ORDER]: state => toggleLoading(state, 'getOrder', false),
  [firstMileTypes.UPDATE_CURRENT_SESSION]: (state, action) => {
    return state.set('currentSession', action.session)
  },
  [firstMileTypes.RESET_SESSION]: () => INITIAL_STATE,
  [firstMileTypes.REQUEST_GET_SHIPPERS]: state => {
    state = toggleLoading(state, 'getShippers', true)
    return state.setIn(['shippers', 'data'], []).setIn(['shippers', 'updated'], false)
  },
  [firstMileTypes.SUCCESS_GET_SHIPPERS]: (state, action) => {
    state = toggleLoading(state, 'getShippers', false)
    return state.setIn(['shippers', 'data'], action.shippers)
  },
  [firstMileTypes.FAIL_GET_SHIPPERS]: state => {
    state = toggleLoading(state, 'getShippers', false)
    return state.setIn(['shippers', 'data'], [])
  },
  [firstMileTypes.SELECT_SHIPPER]: (state, action) => {
    const { shipper } = action
    const { path } = getValueAndPath(state, 'senderDetails')
    return updateSessionValues(state, path, shipper)
  },
  [firstMileTypes.RESET_SHIPPER]: state => {
    const { path } = getValueAndPath(state, 'senderDetails')
    state = state.setIn(['shippers', 'data'], []).setIn(['shippers', 'source'], null)
    return updateSessionValues(state, path, null)
  },
  [firstMileTypes.REQUEST_CREATE_SHIPPER]: state => toggleLoading(state, 'createShipper', true),
  [firstMileTypes.SUCCESS_CREATE_SHIPPER]: state => toggleLoading(state, 'createShipper', false),
  [firstMileTypes.FAIL_CREATE_SHIPPER]: state => toggleLoading(state, 'createShipper', false),
  [firstMileTypes.REQUEST_EDIT_SHIPPER]: state => toggleLoading(state, 'editShipper', true),
  [firstMileTypes.SUCCESS_EDIT_SHIPPER]: state => toggleLoading(state, 'editShipper', false),
  [firstMileTypes.FAIL_EDIT_SHIPPER]: state => toggleLoading(state, 'editShipper', false),
  [firstMileTypes.FETCH_SHIPPER_SETUP_STATUS]: (state, action) => state.set('shipperSetupStatus', action.status),
  [firstMileTypes.REQUEST_SHIPPER_SETUP_STATUS]: (state, action) => {
    return state.set('shipperSetupStatus', action.status)
  },
  [firstMileTypes.RESET_SHIPPER_SETUP_STATUS]: state => state.set('shipperSetupStatus', false),
  [firstMileTypes.REMOVE_PARCEL]: (state, action) => {
    const { index } = action
    const { value: parcels, path: parcelsPath } = getValueAndPath(state, 'parcels')
    parcels.splice(index, 1)
    return updateSessionValues(state, parcelsPath, [...parcels])
  },
  [firstMileTypes.REMOVE_ORDER]: (state, action) => {
    const { index } = action
    const { value: orders, path: ordersPath } = getValueAndPath(state)
    orders.splice(index, 1)
    return updateSessionValues(state, ordersPath, [...orders])
  },
  [firstMileTypes.REQUEST_UPLOAD_IMAGE]: state => toggleLoading(state, 'uploadImage', true),
  [firstMileTypes.SUCCESS_UPLOAD_IMAGE]: (state, action) => {
    const { image, index } = action
    const { value: parcels, path: parcelsPath } = getValueAndPath(state, 'parcels')
    const parcel = parcels[index]
    parcel.recipientDetails = { image }
    parcels[index] = parcel
    state = toggleLoading(state, 'uploadImage', false)
    return updateSessionValues(state, parcelsPath, [...parcels])
  },
  [firstMileTypes.FAIL_UPLOAD_IMAGE]: state => toggleLoading(state, 'uploadImage', false),
  [firstMileTypes.RESET_IMAGE]: (state, action) => {
    const { index } = action
    const { value: parcels, path: parcelsPath } = getValueAndPath(state, 'parcels')
    const parcel = parcels[index]
    parcel.recipientDetails = {}
    return updateSessionValues(state, parcelsPath, [...parcels])
  },
  [firstMileTypes.UPDATE_PARCEL]: (state, action) => {
    const { parcel, index } = action
    const { value: parcels, path: parcelsPath } = getValueAndPath(state, 'parcels')
    parcels[index] = parcel
    return updateSessionValues(state, parcelsPath, [...parcels])
  },
  [firstMileTypes.UPDATE_ORDER]: (state, action) => {
    const { order, index } = action
    const { value: orders, path: ordersPath } = getValueAndPath(state)
    orders[index] = order
    return updateSessionValues(state, ordersPath, [...orders])
  },
  [firstMileTypes.REQUEST_GET_PRICE]: state => {
    state = toggleLoading(state, GET_PRICE, true)
    return state
  },
  [firstMileTypes.SUCCESS_GET_PRICE]: (state, action) => {
    const { price, index } = action
    const { value: parcels, path: parcelsPath } = getValueAndPath(state, 'parcels')
    const parcel = parcels[index]
    parcel.cost = price
    state = toggleLoading(state, GET_PRICE, false)
    return updateSessionValues(state, parcelsPath, [...parcels])
  },
  [firstMileTypes.FAIL_GET_PRICE]: state => {
    state = toggleLoading(state, GET_PRICE, false)
    return state
  },
  [firstMileTypes.RESET_PRICE]: (state, action) => {
    const { index } = action
    const { value: parcels, path: parcelsPath } = getValueAndPath(state, 'parcels')
    const parcel = parcels[index]
    _.set(parcel, 'cost.total', 0.0)
    _.set(parcel, 'cost.insurance', 0.0)
    _.set(parcel, 'cost.deliveryFee', 0.0)
    _.set(parcel, 'cost.cod', { amount: 0.0, tax: 0.0 })
    state = toggleLoading(state, GET_PRICE, false)
    return updateSessionValues(state, parcelsPath, [...parcels])
  },
  [firstMileTypes.RESET_ORDER]: state => {
    const currentSession = state.get('currentSession')
    const { path } = getValueAndPath(state)
    const parcel = isEqual(currentSession, ORDER_TYPES.post) ? DEFAULT_POST_PARCEL : DEFAULT_PACK_PARCEL
    const order = fromJS({}).set('parcels', [parcel])
    return updateSessionValues(state, path, order)
  },
  [firstMileTypes.REQUEST_LODGE_IN]: state => toggleLoading(state, 'createOrder', true),
  [firstMileTypes.REQUEST_CREATE_POST_ORDER]: state => toggleLoading(state, 'createOrder', true),
  [firstMileTypes.REQUEST_CREATE_PACK_ORDER]: state => toggleLoading(state, 'createOrder', true),
  [firstMileTypes.FINISH_CREATE_ORDER]: (state, action) => {
    state = toggleLoading(state, 'createOrder', false)
    const session = state.get('currentSession')
    const { orders } = action
    if (isEqual(session, ORDER_TYPES.send) || isEqual(session, ORDER_TYPES.return)) {
      const { path } = getValueAndPath(state)
      return updateSessionValues(state, path, [...orders])
    } else {
      const { path } = getValueAndPath(state, 'parcels')
      return updateSessionValues(state, path, [...orders])
    }
  },
  [firstMileTypes.REQUEST_BULK_LODGE_IN]: state => {
    state = toggleLoading(state, 'bulkLodgeIn', true)
    return state.setIn(['bulkLodgeInStatus', 'successfulOrders'], 0).setIn(['bulkLodgeInStatus', 'failedOrders'], [])
  },
  [firstMileTypes.FINISH_BULK_LODGE_IN]: (state, action) => {
    state = toggleLoading(state, 'bulkLodgeIn', false)
    return state
      .setIn(['bulkLodgeInStatus', 'successfulOrders'], action.successfulOrders)
      .setIn(['bulkLodgeInStatus', 'failedOrders'], action.failedOrders)
  },
  [firstMileTypes.SUCCESS_SET_DEFAULT_SHIPPER]: state => state.setIn(['shippers', 'updated'], true),
  [firstMileTypes.SUCCESS_GET_RECEIPT_ID]: (state, action) => {
    const { path: basePath } = getValueAndPath(state)
    return updateSessionValues(state, [...basePath, 'receiptId'], action.id)
  },
  [firstMileTypes.FAIL_GET_RECEIPT_ID]: state => {
    const { path: basePath } = getValueAndPath(state)
    return updateSessionValues(state, [...basePath, 'receiptId'], null)
  },
  [firstMileTypes.SUCCESS_GET_EDIT_ORDER_DETAILS]: (state, action) => {
    const { order, country } = action
    const { senderDetails, parcels, ...rest } = constructEditOrderDetails(order, country)
    const { path } = getValueAndPath(state)
    const updatedOrder = fromJS({ ...rest })
      .set('senderDetails', senderDetails)
      .set('parcels', parcels)
    return updateSessionValues(state, path, updatedOrder)
  },
  [firstMileTypes.SUCCESS_GENERATE_RECEIPT]: state => state.set('generateReceiptFailed', false),
  [firstMileTypes.FAIL_GENERATE_RECEIPT]: state => state.set('generateReceiptFailed', true),
  // track the time taken for creating an order
  [firstMileTypes.START_NEW_ORDER]: (state, action) => state.set('startNewOrder', action.startTime),

  [firstMileTypes.UPDATE_BULK_OC_ORDERS]: (state, { orders }) => {
    return setIn(state, ['bulkOC', 'orders'], orders)
  },
  [firstMileTypes.UPDATE_SUCCESSFUL_SHIPPERS]: state => {
    const bulkOCorders = state.getIn(['bulkOC', 'orders']).toJS()
    const successfulBulkOCorders = bulkOCorders.filter(o => _.get(o, 'status.type') === OC_STATUS.success)
    const successfulShipperIds = _.uniq(successfulBulkOCorders.map(o => _.get(o, 'shipper_id')))
    const { value: senderDetails, path: senderDetailPath } = getValueAndPath(state, 'senderDetails')
    const { value: receiptIds, path: receiptIdPath } = getValueAndPath(state, 'receiptId')
    const updatedSenders = senderDetails.filter(sender => successfulShipperIds.includes(sender.id.toString()))
    const updatedReceiptId = successfulShipperIds.reduce(
      (updatedReceipts, receiptId) => ({
        ...updatedReceipts,
        [receiptId]: _.get(receiptIds, receiptId)
      }),
      {}
    )
    return state.setIn(senderDetailPath, updatedSenders).setIn(receiptIdPath, updatedReceiptId)
  },
  [firstMileTypes.UPDATE_SHIPPER_PERSONAL_INFO]: (state, action) => {
    const { shipper: updatedShipper } = action
    const { value: currentShipper, path } = getValueAndPath(state, 'senderDetails')

    _.set(currentShipper, 'signature', updatedShipper.signature)
    return updateSessionValues(state, path, currentShipper)
  }
})
