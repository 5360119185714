// @flow

import type { ConsigneeInfoPayload } from '@app/types/ConsigneeInfoPayload'
import type { ConsigneeInfoResponse } from '@app/types/ConsigneeInfo'
import type { ResponseData } from '@app/types/ResponseData'
import { mockUserInfo } from '@app/constants'

const { MOCK_DP_ID, MOCK_CONTACT_NUMBER, MOCK_SHIPPER_ID, MOCK_GLOBAL_SHIPPER_ID } = mockUserInfo


export default (country: string, payload: ConsigneeInfoPayload): Promise<ResponseData<ConsigneeInfoResponse>> => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve({
        data: {
          data: [{
            id: 3643,
            dp_id: MOCK_DP_ID,
            legacy_shipper_id: MOCK_SHIPPER_ID,
            global_shipper_id: MOCK_GLOBAL_SHIPPER_ID,
            name: 'khalid',
            email: 'khalid@mail.com',
            phone_number: MOCK_CONTACT_NUMBER,
            address_1: 'a',
            address_2: null,
            locality: 'Kramat Jati',
            state: 'DKI Jakarta',
            city: 'Kota Jakarta Timur',
            country: 'id',
            postcode: '00000',
            latitude: -6.2748585,
            longitude: 106.862425442281
          }]
        },
        ok: true
      })
    }, 1000)
  })
}
