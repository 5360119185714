import { createActions, createReducer } from 'reduxsauce'

import { fromJS } from 'immutable'
import { setLoading } from '@app/utils/reducerUtils'

export const { Types: ShipperSearchTypes, Creators: ShipperSearchCreators } = createActions(
  {
    requestGetShippers: ['value', 'searchAll', 'form', 'messages'],
    successGetShippers: ['shippers'],
    failGetShippers: [],
    requestSetDefaultShipper: ['id'],
    successSetDefaultShipper: ['id'],
    failSetDefaultShipper: [],
    requestCreateShipper: ['shipper', 'messages'],
    successCreateShipper: [],
    failCreateShipper: [],
    selectShipper: ['shipper'],
    clearResult: [],
    fetchShipperSetupStatus: ['status'],
    requestShipperSetupStatus: ['legacyShipperId', 'globalShipperId'],
    setSelectedShipperIsCodEnabled: ['isCodEnabled', 'handlers'],
    setSelectedShipperSignature: ['signature', 'handlers'],
    setSelectedShipperBankDetails: ['bankName', 'bankBranch', 'bankAccountNumber']
  },
  { prefix: 'ShipperSearch/' }
)

export const INITIAL_STATE = fromJS({
  loading: {},
  shippers: [],
  selectedShipper: null,
  shipperUpdated: false,
  shipperSetupStatus: false
})

export const shipperSearchReducer = createReducer(INITIAL_STATE, {
  [ShipperSearchTypes.REQUEST_GET_SHIPPERS]: state => {
    state = setLoading(state, 'getShippers', true)
    return state.set('shippers', []).set('shipperUpdated', false)
  },
  [ShipperSearchTypes.REQUEST_SHIPPER_SETUP_STATUS]: (state, action) => {
    return state.set('shipperSetupStatus', action.status)
  },
  [ShipperSearchTypes.SUCCESS_GET_SHIPPERS]: (state, action) => {
    state = setLoading(state, 'getShippers', false)
    return state.set('shippers', action.shippers).set('shipperUpdated', false)
  },
  [ShipperSearchTypes.FAIL_GET_SHIPPERS]: state => {
    state = setLoading(state, 'getShippers', false)
    return state.set('shippers', [])
  },
  [ShipperSearchTypes.SELECT_SHIPPER]: (state, action) => state.set('selectedShipper', action.shipper),
  [ShipperSearchTypes.SUCCESS_SET_DEFAULT_SHIPPER]: state => state.set('shipperUpdated', true),
  [ShipperSearchTypes.FETCH_SHIPPER_SETUP_STATUS]: (state, action) => state.set('shipperSetupStatus', action.status),
  [ShipperSearchTypes.CLEAR_RESULT]: () => INITIAL_STATE,
  [ShipperSearchTypes.SET_SELECTED_SHIPPER_IS_COD_ENABLED]: (state, action) => {
    const selectedShipper = state.get('selectedShipper', {})
    selectedShipper['isCodEnabled'] = action.isCodEnabled
    return state.set(['selectedShipper'], selectedShipper)
  },
  [ShipperSearchTypes.SET_SELECTED_SHIPPER_SIGNATURE]: (state, action) => {
    const selectedShipper = state.get('selectedShipper', {})
    selectedShipper['signature'] = action.signature
    return state.set(['selectedShipper'], selectedShipper)
  },
  [ShipperSearchTypes.SET_SELECTED_SHIPPER_BANK_DETAILS]: (state, action) => {
    const selectedShipper = state.get('selectedShipper', {})
    selectedShipper['bankName'] = action.bankName
    selectedShipper['bankBranch'] = action.bankBranch
    selectedShipper['bankAccountNumber'] = action.bankAccountNumber
    return state.set(['selectedShipper'], selectedShipper)
  }
})
